.navbar {
  background-image: linear-gradient(to bottom right, #33ccb7, #3f6dc4);
  font-size: 21px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
#foot {
  padding-top: 100px;
  padding-bottom: 200px;
}
#sectRow {
  padding-top: 50px;
}

.navbar-collapse {
  float: right;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#objCard {
  margin: 15px;
  padding: 20px;
  border-radius: 20px;
  border: solid 1px black;
}
#arrCard {
  margin: 15px;
  padding: 20px;
  border: solid 1px black;
}
#objKey {
  position: relative;

  z-index: 1;
  display: block;
  margin: 0.2em 0;
  padding-left: 20px;
  width: 100%;
  border: 2px solid #d6dee0;
  border-left: 5px solid #d6dee0;
  transition: background-color 0.2s ease-out, border 0.2s ease-out;
}
#objKey:hover{
  border-color:#5286c0;
}
#objKeyT {
  color: #5286c0;
}
#objValS {
  color: #b03535;
}
#objValN {
  color: #47a483;
}
#objValB {
  color: #3737ff;
}

@media (min-width: 1200px) {
  p {
    font-size: 1.2rem;
  } /*1rem = 16px*/
}
